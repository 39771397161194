import { useState } from 'react';
import './initialAlert.css';

function InitialAlert() {
    const [display, setDisplay] = useState("block");
    const handleClick = (e) => {
        setDisplay("none");
    }
  return <div id="container-initial" style={{display}} onClick={handleClick}>
      <p id="exit">X</p>
      <p id="title-alert">This map is interactive!</p>
      <ul>
        <li>
            Use your mouse scroll wheel (or
            pinch on a touch screen) to zoom
            in/out. Click and drag to pan around.
        </li>
        <li>
            Most items in the map can be
            clicked to open a new browser tab
            with more detailed information about
            that item.
        </li>
      </ul>
      <p id="got-it">Got it.</p>
  </div>;
}

export default InitialAlert;
