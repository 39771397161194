const popUps = [
    {  
        link: "https://en.wikipedia.org/wiki/Flowchart",
        popUp: {
            description: "Flowcharts (show a process or workflow). An example of a diagram (shows categorical/relational information) that people also call a “chart”.",
        },
        clickableArea: {
            top: '21.61%',
            left: '11.68%',
            width: "2.94%",
            height: "2.86%"
        }
    }, {
        link: "https://www.autodesk.com/",
        popUp: {
            description: "Autodesk AutoCAD (computer-aided design software).",
        },
        clickableArea: {
            top: '18.51%',
            left: '2.57%',
            width: "2.94%",
            height: "1.11%"
        }
    }, {
        link: "https://en.wikipedia.org/wiki/Computer-aided_design",
        popUp: {
            description: "CAD (Computer-Aided Design) diagrams, typically for engineering. An example of a diagram that people typically don’t call a “chart” or a “graph”.",
        },
        clickableArea: {
            top: '21.21%',
            left: '3.18%',
            width: "3.85%",
            height: "4.21%"
        }
    }, {
        link: "https://www.bbc.com/culture/article/20150720-the-london-underground-map-the-design-that-shaped-a-city",
        popUp: {
            description: "Subway maps, such as the classic London Underground map. An example of a diagram (shows relational information) that’s also a map (locations in the image correspond to locations in a space) and a chart.",
        },
        clickableArea: {
            top: '26.78%',
            left: '9.60%',
            width: "3.67%",
            height: "4.37%"
        }
    }, {
        link: "",
        popUp: {
            description: "Storm track map. An example of a diagram (shows relational information) that’s also a map (locations in the image correspond to locations in a space) and a graph (shows quantities of time, wind speed, etc. as shapes/colors).",
        },
        clickableArea: {
            top: '26.86%',
            left: '14.43%',
            width: "3.30%",
            height: "4.45%"
        }
    }, {
        link: "https://www.google.com/maps",
        popUp: {
            description: "Google Maps (mapping website)",
        },
        clickableArea: {
            top: '35.83%',
            left: '1.35%',
            width: "2.45%",
            height: "2.54%"
        }
    }, {
        link: "https://en.wikipedia.org/wiki/Charles_Joseph_Minard#The_map_of_Napoleon's_Russian_campaign",
        popUp: {
            description: "Charles Minard’s 1869 map of Napoleon’s invasion of Russia. An example of a map (locations in the image correspond to locations in a space) that’s also a graph (shows quantities of soldiers as line thickness, etc.)",
        },
        clickableArea: {
            top: '33.53%',
            left: '13.52%',
            width: "3.73%",
            height: "3.65%"
        }
    }, {
        link: "",
        popUp: {
            description: "COVID case count map. An example of a map (locations in the image correspond to locations in a space)  that’s also a graph (shows quantities of cases as shapes/colors).",
        },
        clickableArea: {
            top: '33.69%',
            left: '17.61%',
            width: "4.46%",
            height: "3.50%"
        }
    }, {
        link: "",
        popUp: {
            description: "Luna (Nick’s dog). An example of a raster image (was originally created as a grid of colored pixels) that people wouldn’t call a “chart” and that’s not a graph (doesn’t show quantities as shapes/colors).",
        },
        clickableArea: {
            top: '48.31%',
            left: '6.06%',
            width: "3.43%",
            height: "5.16%"
        }
    }, {
        link: "https://www.adobe.com/products/photoshop.html",
        popUp: {
            description: "Adobe Photoshop (image creation and editing software).",
        },
        clickableArea: {
            top: '50.06%',
            left: '2.09%',
            width: "3.32%",
            height: "1.36%"
        },
    }, {
        link: "https://www.blender.org/",
        popUp: {
            description: "Blender (3D modelling and rendering software).",
        },
        clickableArea: {
            top: '54.69%',
            left: '6.64%',
            width: "2.46%",
            height: "1.20%"
        }
    }, {
        link: "",
        popUp: {
            description: "Atmospheric temperature map. An example of a map (locations in the image correspond to locations in a space) that’s also a raster image (was originally created as a grid of colored pixels) and a graph (shows quantity of heat as color).",
        },
        clickableArea: {
            top: '39.52%',
            left: '14.26%',
            width: "3.56%",
            height: "4.47%"
        }
    }, {
        link: "",
        popUp: {
            description: "MRI (Magnetic Resonance Imaging) image. An example of a raster image (was originally created as a grid of colored pixels) that’s also a graph (shows quantity of blood flow as color).",
        },
        clickableArea: {
            top: '44.63%',
            left: '17.95%',
            width: "4.00%",
            height: "4.71%"
        }
    }, {
        link: "https://en.wikipedia.org/wiki/Scatter_plot",
        popUp: {
            description: "Scatterplot. Chart type for showing correlation between variables. An example of a graph that’s not a diagram, map, raster image, or table.",
        },
        clickableArea: {
            top: '36.73%',
            left: '23.17%',
            width: "4.30%",
            height: "5.27%"
        }
    }, {
        link: "https://en.wikipedia.org/wiki/Small_multiple",
        popUp: {
            description: "Small multiples. A set of related graphs in a tabular arrangement. An example of a table that’s also a graph (shows quantities as shapes/colors).",
        },
        clickableArea: {
            top: '45.43%',
            left: '27.90%',
            width: "4.55%",
            height: "5.27%"
        }
    }, {
        link: "",
        popUp: {
            description: "Summary table. A table that people often call a “chart” but not a “graph” (doesn’t show quantitative information as shapes/colors).",
        },
        clickableArea: {
            top: '46.87%',
            left: '36.26%',
            width: "3.75%",
            height: "4.95%"
        }
    }, {
        link: "https://en.wikipedia.org/wiki/SQL",
        popUp: {
            description: "The SQL database query language.",
        },
        clickableArea: {
            top: '55.81%',
            left: '38.35%',
            width: "1.72%",
            height: "1.68%"
        }
    }, {
        link: "",
        popUp: {
            description: "Unaggregated (“raw”) data table. A type of table that people don’t usually call a “chart”.",
        },
        clickableArea: {
            top: '49.90%',
            left: '38.9%',
            width: "6.21%",
            height: "5.51%"
        }
    }, {
        link: "",
        popUp: {
            description: "Tables with graphical elements. Examples of tables that are also graphs (show quantities as shapes/colors).",
        },
        clickableArea: {
            top: '40.64%',
            left: '29.75%',
            width: "7.81%",
            height: "4.07%"
        }
    }, {
        link: "",
        popUp: {
            description: "Bar chart. An example of a graph that isn’t a diagram, map, raster image, or table.",
        },
        clickableArea: {
            top: '33.77%',
            left: '28.40%',
            width: "4.67%",
            height: "4.39%"
        }
    }, {
        link: "",
        popUp: {
            description: "Line chart. An example of a graph that isn’t a diagram, map, raster image, or table.",
        },
        clickableArea: {
            top: '31.14%',
            left: '23.29%',
            width: "4.30%",
            height: "4.55%"
        }
    }, {
        link: "https://www.microsoft.com/en-us/microsoft-365/excel",
        popUp: {
            description: "Microsoft Excel (spreadsheet and data visualization software).",
        },
        clickableArea: {
            top: '31.70%',
            left: '28.15%',
            width: "2.34%",
            height: "1.44%"
        }
    }, {
        link: "https://www.microsoft.com/en-us/microsoft-365/project/project-management-software",
        popUp: {
            description: "Microsoft Project (project management software).",
        },
        clickableArea: {
            top: '26.43%',
            left: '18.68%',
            width: "2.27%",
            height: "0.96%"
        }
    }, {
        link: "https://en.wikipedia.org/wiki/Gantt_chart",
        popUp: {
            description: "Gantt chart (shows the durations and dependencies of parts of a project). An example of a diagram (shows relational information) that’s also a graph (shows quantitative information like task durations as shapes/colors).",
        },
        clickableArea: {
            top: '22.99%',
            left: '16.78%',
            width: "3.69%",
            height: "3.27%"
        }
    }, {
        link: "https://en.wikipedia.org/wiki/Sankey_diagram",
        popUp: {
            description: "Sankey diagram (shows flows of quantities among stages). An example of a diagram (shows categorical/relational information) that’s also a graph (shows quantitative information as shapes/colors).",
        },
        clickableArea: {
            top: '20.52%',
            left: '18.19%',
            width: "4.43%",
            height: "2.71%"
        }
    }, {
        link: "https://www.microsoft.com/en-us/microsoft-365/powerpoint",
        popUp: {
            description: "Microsoft PowerPoint (slide presentation software).",
        },
        clickableArea: {
            top: '18.92%',
            left: '17.02%',
            width: "2.95%",
            height: "0.88%"
        }
    }, {
        link: "https://en.wikipedia.org/wiki/Organizational_chart",
        popUp: {
            description: "Org chart (shows the hierarchy of members of an organization). An example of a diagram (shows categorical/relational information) that people also call a “chart”.",
        },
        clickableArea: {
            top: '15.17%',
            left: '16.66%',
            width: "3.01%",
            height: "3.35%"
        }
    }, {
        link: "https://en.wikipedia.org/wiki/Venn_diagram",
        popUp: {
            description: "Venn diagram (shows how sets of items are related to one another). An example of a diagram (shows categorical/relational information) that people also call a “chart”.",
        },
        clickableArea: {
            top: '17.81%',
            left: '13.71%',
            width: "3.50%",
            height: "3.35%"
        }
    }, {
        link: "https://www.lucidchart.com/",
        popUp: {
            description: "Lucidchart (diagram creation software).",
        },
        clickableArea: {
            top: '21.80%',
            left: '15.06%',
            width: "1.48%",
            height: "1.52%"
        }
    }, {
        link: "https://depictdatastudio.com/soar-beyond-the-dusty-shelf-report/",
        popUp: {
            description: "Ann K. Emery’s “Soar Beyond the Dusty Shelf Report” course.",
        },
        clickableArea: {
            top: '26.99%',
            left: '54.33%',
            width: "3.44%",
            height: "4.71%"
        }
    }, {
        link: "https://www.microsoft.com/en-us/microsoft-365/word",
        popUp: {
            description: "Microsoft Word (word processing software).",
        },
        clickableArea: {
            top: '24.19%',
            left: '62.32%',
            width: "1.91%",
            height: "1.20%"
        }
    }, {
        link: "https://www.bigbookofdashboards.com/",
        popUp: {
            description: "Steve Wexler, Jeffrey Shaffer & Andy Cotgreave’s “The Big Book of Dashboards” book.",
        },
        clickableArea: {
            top: '35.85%',
            left: '75.11%',
            width: "6.02%",
            height: "2.24%"
        }
    }, {
        link: "https://www.practicalreporting.com/practical-dashboards-detailed-course-description",
        popUp: {
            description: "Nick Desbarats’ “Practical Dashboards” course and book.",
        },
        clickableArea: {
            top: '25.55%',
            left: '78.43%',
            width: "3.07%",
            height: "3.83%"
        }
    }, {
        link: "https://powerbi.microsoft.com/",
        popUp: {
            description: "Microsoft Power BI (business intelligence and dashboard design software).",
        },
        clickableArea: {
            top: '23.47%',
            left: '84.39%',
            width: "2.70%",
            height: "0.96%"
        }
    }, {
        link: "https://www.practicalreporting.com/practical-dashboards-detailed-course-description",
        popUp: {
            description: "Example of a status monitoring dashboard from Nick Desbarats’ “Practical Dashboards” course.",
        },
        clickableArea: {
            top: '26.43%',
            left: '81.44%',
            width: "3.87%",
            height: "4.87%"
        }
    }, {
        link: "https://www.staceybarr.com/books/practicalperformancemeasurement/",
        popUp: {
            description: "Stacey Barr’s “Practical Performance Measurement” book.",
        },
        clickableArea: {
            top: '30.02%',
            left: '78.18%',
            width: "3.50%",
            height: "4.87%"
        }
    }, {
        link: "https://www.staceybarr.com/books/practicalperformancemeasurement/",
        popUp: {
            description: "Example of a KPI (performance) dashboard from Stacey Barr’s “Practical Performance Measurement” book.",
        },
        clickableArea: {
            top: '31.93%',
            left: '81.62%',
            width: "3.56%",
            height: "4.55%"
        }
    }, {
        link: "https://en.wikipedia.org/wiki/Customer_relationship_management",
        popUp: {
            description: "Customer Relation Management Systems (software for tracking and managing an organization’s customers and sales prospects). An example of a type of vertical application.",
        },
        clickableArea: {
            top: '29.14%',
            left: '89.40%',
            width: "5.10%",
            height: "5.27%"
        }
    }, {
        link: "https://en.wikipedia.org/wiki/Accounting_software",
        popUp: {
            description: "Accounting Applications (software for tracking and managing financial transactions). An example of a type of vertical application.",
        },
        clickableArea: {
            top: '36.25%',
            left: '90.17%',
            width: "3.56%",
            height: "4.71%"
        }
    }, {
        link: "https://en.wikipedia.org/wiki/Human_resource_management_system",
        popUp: {
            description: "Human Resources Applications (software for tracking and managing an organization’s employees). An example of a type of vertical application.",
        },
        clickableArea: {
            top: '42.80%',
            left: '89.64%',
            width: "4.73%",
            height: "5.59%"
        }
    }, {
        link: "",
        popUp: {
            description: "Many video games feature charts to show player performance, available resources, etc.",
        },
        clickableArea: {
            top: '49.90%',
            left: '90.10%',
            width: "3.81%",
            height: "4.39%"
        }
    }, {
        link: "https://www.qlik.com/us/products/qlik-sense",
        popUp: {
            description: "Qlik Sense (dashboard and data visualization software).",
        },
        clickableArea: {
            top: '41.68%',
            left: '83.90%',
            width: "2.70%",
            height: "1.12%"
        }
    }, {
        link: "https://d3js.org/",
        popUp: {
            description: "D3.js (code library for creating custom data visualizations in the JavaScript programming language).",
        },
        clickableArea: {
            top: '50.54%',
            left: '84.82%',
            width: "1.72%",
            height: "1.20%"
        }
    }, {
        link: "",
        popUp: {
            description: "Interactives are charts or sets of charts that are usually published by media outlets and that allow users to interact with data in simple ways.",
        },
        clickableArea: {
            top: '50.86%',
            left: '80.95%',
            width: "3.50%",
            height: "3.67%"
        }
    }, {
        link: "https://www.tibco.com/products/tibco-spotfire",
        popUp: {
            description: "TIBCO Spotfire (data exploration and visualization software).",
        },
        clickableArea: {
            top: '42.80%',
            left: '75.97%',
            width: "1.97%",
            height: "0.88%"
        }
    }, {
        link: "http://www.stephen-few.com/nysi.php",
        popUp: {
            description: "Stephen Few’s “Now You See It” book.",
        },
        clickableArea: {
            top: '47.19%',
            left: '75.35%',
            width: "2.52%",
            height: "3.91%"
        }
    }, {
        link: "https://www.tableau.com/products/desktop",
        popUp: {
            description: "Tableau Desktop (data exploration and visualization software).",
        },
        clickableArea: {
            top: '41.44%',
            left: '70.07%',
            width: "2.34%",
            height: "1.20%"
        }
    }, {
        link: "https://d3js.org/",
        popUp: {
            description: "D3.js (code library for creating custom data visualizations in the JavaScript programming language).",
        },
        clickableArea: {
            top: '46.63%',
            left: '66.56%',
            width: "1.78%",
            height: "1.20%"
        }
    }, {
        link: "",
        popUp: {
            description: "Scrollyboards are sequences of charts that are intended to be scrolled through vertically, often with animations that are linked to scrolling motion.",
        },
        clickableArea: {
            top: '42.96%',
            left: '63.68%',
            width: "2.82%",
            height: "10.70%"
        }
    }, {
        link: "https://www.ted.com/talks/hans_rosling_the_best_stats_you_ve_ever_seen",
        popUp: {
            description: "Video of health statistician Hans Rosling’s TED talk in which he vividly narrates an animated bubble chart.",
        },
        clickableArea: {
            top: '49.50%',
            left: '57.60%',
            width: "4.55%",
            height: "4.15%"
        }
    }, {
        link: "https://www.microsoft.com/en-us/microsoft-365/powerpoint",
        popUp: {
            description: "Microsoft PowerPoint (slide presentation software).",
        },
        clickableArea: {
            top: '42.00%',
            left: '55.81%',
            width: "3.13%",
            height: "0.88%"
        }
    }, {
        link: "https://www.jmp.com/en_us/home.html",
        popUp: {
            description: "SAS JMP (data exploration and visualization software).",
        },
        clickableArea: {
            top: '44.55%',
            left: '76.03%',
            width: "1.41%",
            height: "1.12%"
        }
    }, {
        link: "https://www.adobe.com/products/illustrator.html",
        popUp: {
            description: "Adobe Illustrator (graphic design and illustration software).",
        },
        clickableArea: {
            top: '77.61%',
            left: '26.43%',
            width: "2.83%",
            height: "1.28%"
        }
    }, {
        link: "https://www.tableau.com/products/desktop",
        popUp: {
            description: "Tableau Desktop (data exploration and visualization software).",
        },
        clickableArea: {
            top: '79.53%',
            left: '33.07%',
            width: "2.34%",
            height: "1.04%"
        }
    }, {
        link: "https://www.microsoft.com/en-us/microsoft-365/excel",
        popUp: {
            description: "Microsoft Excel (spreadsheet and data visualization software).",
        },
        clickableArea: {
            top: '73.62%',
            left: '36.75%',
            width: "1.66%",
            height: "0.88%"
        }
    }, {
        link: "http://www.stephen-few.com/smtn.php",
        popUp: {
            description: "Stephen Few’s “Show Me the Numbers” book.",
        },
        clickableArea: {
            top: '77.93%',
            left: '37.55%',
            width: "3.56%",
            height: "3.51%"
        }
    }, {
        link: "https://www.jmp.com/en_us/home.html",
        popUp: {
            description: "SAS JMP (data visualization and exploration software).",
        },
        clickableArea: {
            top: '81.68%',
            left: '35.59%',
            width: "1.72%",
            height: "1.04%"
        }
    }, {
        link: "https://matplotlib.org/",
        popUp: {
            description: "matplotlib (code library for creating custom data visualizations in the Python programming language).",
        },
        clickableArea: {
            top: '88.47%',
            left: '36.14%',
            width: "3.01%",
            height: "0.96%"
        }
    }, {
        link: "https://ggplot2.tidyverse.org/index.html",
        popUp: {
            description: "ggplot (code library for creating custom data visualizations in the R programming language).",
        },
        clickableArea: {
            top: '85.36%',
            left: '32.94%',
            width: "2.03%",
            height: "0.96%"
        }
    }, {
        link: "https://link.springer.com/book/10.1007/0-387-28695-0 ",
        popUp: {
            description: "Leland Wilkinson’s “The Grammar of Graphics” book.",
        },
        clickableArea: {
            top: '86.39%',
            left: '30.05%',
            width: "3.69%",
            height: "4.07%"
        }
    }, {
        link: "https://d3js.org/",
        popUp: {
            description: "D3.js (code library for creating custom data visualizations in the JavaScript programming language).",
        },
        clickableArea: {
            top: '83.04%',
            left: '30.85%',
            width: "1.72%",
            height: "1.20%"
        }
    }, {
        link: "https://www.visualcinnamon.com/portfolio/",
        popUp: {
            description: "Nadieh Bremer’s “Art in Pi - 100,000 Digits”.",
        },
        clickableArea: {
            top: '80.96%',
            left: '27.72%',
            width: "2.95%",
            height: "3.51%"
        }
    }, {
        link: "https://informationisbeautiful.net/books/",
        popUp: {
            description: "David McCandless’ “Information Is Beautiful” book.",
        },
        clickableArea: {
            top: '76.81%',
            left: '19.05%',
            width: "3.32%",
            height: "4.23%"
        }
    }, {
        link: "https://www.practicalreporting.com/practical-charts-detailed-course-description",
        popUp: {
            description: "Nick Desbarats’ “Practical Charts” course and book.",
        },
        clickableArea: {
            top: '74.09%',
            left: '51.26%',
            width: "2.70%",
            height: "4.15%"
        }
    }, {
        link: "https://www.storytellingwithdata.com/books",
        popUp: {
            description: "Cole Nussbaumer Knaflic’s “Storytelling With Data” book.",
        },
        clickableArea: {
            top: '86.71%',
            left: '80.15%',
            width: "3.26%",
            height: "4.39%"
        }
    }, {
        link: "https://www.effectivedatastorytelling.com/",
        popUp: {
            description: "Brent Dykes’ “Effective Data Storytelling” book.",
        },
        clickableArea: {
            top: '88.39%',
            left: '50.03%',
            width: "3.87%",
            height: "4.15%"
        }
    }, {
        link: "https://www.practicalreporting.com/",
        popUp: {
            description: "Nick Desbarats’ consultancy (designer of this map). Click for info regarding upcoming workshops and books.",
        },
        clickableArea: {
            top: '92.78%',
            left: '91.47%',
            width: "8.30%",
            height: "5.51%"
        }
    }, {
        link: "",
        popUp: {
            description: `Jennifer Desbarats’ “Little House". An example of a raster image (was originally created as a grid of colored pixels) that people wouldn’t call a “chart” and that’s not a graph (doesn’t show quantities as shapes/colors).`,
        },
        clickableArea: {
            top: '52.90%',
            left: '10.10%',
            width: "2.5%",
            height: "4.44%"
        }
    }, {
        link: "https://www.logianalytics.com/",
        popUp: {
            description: "Logi Analytics (a vendor of embedded analytics software).",
        },
        clickableArea: {
            top: '28.25%',
            left: '88.27%',
            width: "1.5%",
            height: "1%"
        }
    }, {
        link: "https://looker.com/product/embedded-analytics",
        popUp: {
            description: "Looker (a vendor of embedded analytics software).",
        },
        clickableArea: {
            top: '34.91%',
            left: '93.73%',
            width: "2.12%",
            height: "1.16%"
        }
    }, {
        link: "https://www.sisense.com/product/embedded-analytics/",
        popUp: {
            description: "Sisense (a vendor of embedded analytics software).",
        },
        clickableArea: {
            top: '41.26%',
            left: '88.11%',
            width: "2.12%",
            height: "1.27%"
        }
    }, {
        link: "https://www.adobe.com/products/indesign.html",
        popUp: {
            description: "Adobe InDesign (desktop publishing application).",
        },
        clickableArea: {
            top: '30.15%',
            left: '65.88%',
            width: "2.44%",
            height: "1.16%"
        }
    }

]

export default popUps;