import './map.css';
import {useState, useEffect, useRef} from 'react';
import Zoom from 'react-img-zoom';
import ReactImageZoom from 'react-image-zoom';
import { MapInteractionCSS } from 'react-map-interaction';
import PopUp from '../popUp/PopUp';
import descriptions from './data/descriptions';
import areas from './data/popUps';
import Area from '../area/Area';
import popUps from './data/popUps';
import InitialAlert from '../initialAlert/InitialAlert';

function Map() {
    let click = 0;
    const [tooltipMsg, setToolTipMsg] = useState("");
    const [toolTipLink, setToolTipLink] = useState("");
    const coordinates = [[50, 40, 10, 10]];
    const [hoveredItems, setHoveredItems] = useState([]);
    const [scalePopUp, setScalePopUp] = useState("1.5");
    let prevCoordinates = [0, 0];
    const handleMapClick = (e) => {
        click ++;
        click = click >= 3 ? 1 : click;
        // console.log(document.querySelector(".map-img").getBoundingClientRect())
        const height = (e.clientY / document.querySelector(".inner-container-img").getBoundingClientRect().height) * 100;
        const width = (e.clientX / document.querySelector(".inner-container-img").getBoundingClientRect().width) * 100;
        click == 1 ? console.log((e.clientX / document.querySelector(".inner-container-img").getBoundingClientRect().width) * 100 + "% X " + e.clientY / document.querySelector(".inner-container-img").getBoundingClientRect().height * 100 + "% Y") : console.log(`${width - prevCoordinates[0]} % is diff X ${height - prevCoordinates[1]} is % Y diff`);
        prevCoordinates = [width, height];click == 2 && console.log("------------------------------------------");
    }
    // useEffect(() => {console.log("updated")}, [hoveredItems])
    const [change, setchange] = useState("a");
    const hrefs = ["#aaaaaa"];
    const {innerHeight, innerWidth} = window;
    const handleImgInteraction = (value) => {
        
        const {translation, scale} = value;
        console.log(scale + " IS THE SCALE")
        let scale1 = scale;
        scale1 = 2 / scale1;
        scale1 > 1.5 ? scale1 = 1.7 : scale1 = scale1;
        scale1 = scale1.toString();
        scale1.toString();
        setScalePopUp(`${scale1.replaceAll('', '')}`);
        console.log(scalePopUp)
        scale >= 1 ? setValue(value) : setValue({...value, scale: 1, translation: {x: 0, y: 0}});
    }
    const [value, setValue] = useState({
        scale: 1,
        translation: { x: 0, y: 0 }
    });
    const unsetHoveredItemsProp = (i) => {
        setHoveredItems((prevSetHoveredItems) => {
            prevSetHoveredItems[i] = "0";
            console.log("usnet")
            // setchange("a");
            return prevSetHoveredItems;
        })
    }
    // setTimeout(() => setHoveredItems("o"), 2000)
    const setHoveredItemsProp = (i) => { 
        console.log("set")
        setHoveredItems((prevSetHoveredItems) => {
            // setchange("a")
            prevSetHoveredItems[i] = "1";
            return prevSetHoveredItems;
        });
        console.log(hoveredItems)
    }
    useEffect(() => {
        setHoveredItems(areas.map((cA, i) => "0"));
        // console.log("refresh")
    }, [])
    // useEffect(() => {console.log("a great change has occured")}, [hoveredItems])
    return (
        <div className='container-map' onClick={handleMapClick}>
            <InitialAlert />
            <MapInteractionCSS value={value} onChange={handleImgInteraction} maxScale={20} minScale={0.5}>
                {/* {popUps.map((mainObj, i) => {
                    const { popUp } = mainObj;
                    return <PopUp info={{...popUp, scalePopUp, idProp: i}} link={mainObj.link} styleProp={{transform: `scale(${scalePopUp})`}} hoveredItems={hoveredItems} propId={i} key={i} change={change} />
                }
                )} */}
                <div className='container-img'>
                    <div className='inner-container-img'>
                        <img className="map-img" src="./images/map of data visualization.png" useMap='#dataMap' />
                        {popUps.map((mainObj, i) => {
                            const clcikableArea = mainObj.clickableArea;
                            const { popUp } = mainObj;
                            // console.log(clcikableArea.top + mainObj.clcikableArea.left)
                            return(
                                <Area styleProp={{top: clcikableArea.top, left: clcikableArea.left, width: clcikableArea.width, height: clcikableArea.height}} 
                                    hrefProp={mainObj.link} 
                                    idProp={i} 
                                    setHoveredItemsProp={setHoveredItemsProp}
                                    unsetHoveredItemsProp={unsetHoveredItemsProp} 
                                    onMouseEnter={(i) => unsetHoveredItemsProp(i)}
                                    onMouseLEave={(i) => setHoveredItemsProp(i)}
                                    // key={i}
                                    info={{...popUp, scalePopUp, idProp: i}} 
                                    stylePropInfo={{transform: `scale(${scalePopUp})`}} 
                                    hoveredItems={hoveredItems} 
                                    setToolTipMsg={setToolTipMsg}
                                    setToolTipLink={setToolTipLink}
                                    >
                                </Area>
                            )
                        })} 
                    </div>
                </div>
            </MapInteractionCSS>
            <div className='tool-tip-thing' style={{display: tooltipMsg == "" ? "none" : "block"}}>{tooltipMsg}<br />{toolTipLink != "" && <br /> }<a>{toolTipLink}</a></div>
        </div>
    )
}

export default Map;