import './popUp.css';
import { useState } from 'react';

function PopUp(props) {
    // console.log(props.top + "i")
    return (
        <div className='pop-up' id={props.info.id} style={{top: props.top, left: props.left, transform: `scale(${props.info.scalePopUp}) translate(-50%, -100px)`, opacity: props.oppacity}}>
            {props.info.description}
            <a className='pop-up-link' href={props.link}>{props.link}</a>
        </div>
    )
}
export default PopUp;