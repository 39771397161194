import './area.css';
import PopUp from '../popUp/PopUp';

import React, {useState} from 'react';

function Area(props) {
  const [hovered, sethovered] = useState();
  const [zIndex, setZIndex] = useState("15");
  const [y, setY] = useState();
  const [x, setX] = useState();
  const handleHover = (e) => {
    sethovered("1");
    setZIndex("16");
  }
  const handleLeave = (e) => {
    sethovered("0");
    setZIndex("14")
  }
  const handleMousMove = (e) => {
    setX(e.clientX);
    setY(e.clientY);
  }
  return (
    <div>
      {/* <div className='container-area' style={props.styleProp}onMouseEnter={(e) => handleHover(e)} onMouseLeave={(e) => handleLeave(e)}></div> */}
      {/* <PopUp info={props.info} link={props.link} propId={props.propId} hoveredItems={props.hoveredItems} link={props.hrefProp} oppacity={hovered} top={y} left={x} height={props.styleProp.height} /> */}
      {props.hrefProp === "" ? 
      <div style={{...props.styleProp, transform: 'translate(0)', cursor: "default"}} className='area' /*id={props.id}*/ onMouseEnter={(e) => {handleHover(e); props.setToolTipLink(props.hrefProp)}} onMouseLeave={(e) => {props.setToolTipMsg(""); props.setToolTipLink("")}} onMouseMove={(e) => { props.setToolTipMsg(props.info.description); props.setToolTipLink(props.hrefProp)}}></div>: 
      <a style={{...props.styleProp, transform: 'translate(0)'}} href={props.hrefProp} className='area' /*id={props.id}*/ onMouseEnter={(e) => {handleHover(e); props.setToolTipLink(props.hrefProp)}} onMouseLeave={(e) => {props.setToolTipMsg(""); props.setToolTipLink("")}} onMouseMove={(e) => { props.setToolTipMsg(props.info.description); props.setToolTipLink(props.hrefProp)}} target="_blank"></a>} 
    </div>
  )
}

export default Area;
