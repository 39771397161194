import './App.css';
import Map from './components/map/Map';

function App() {
  return (
    
    <div className="App">
      <head>
      <link rel="stylesheet" type="text/css" href="lol.css" media="all" />
    </head>
    <div className="wrapper">
        <div className="MainContent">
          <div id="TopMenu">
            <p><a href="https://www.youtube.com/watch?v=4InqklhiwTk" target="_blank"><img src="images/guided_tour_thumbnail-small.png" width={58} height={40} /> Guided tour!</a></p>
            <p><a href="#WhatIsThis">What is this?</a></p>
            <p><a href="#WhoMadeIt">Who made it?</a></p>
            <p><a href="#Suggestions">Comments/suggestions?</a></p>
          </div>
          <div style={{clear: 'both'}} />
          <div style={{width: '100%', position: 'relative', backgroundColor: 'none', margin: '10px 0 20px 0', height: 'fit-content', overflow: 'hidden', margin: "35px 0"/*, border: 'solid 2px #eee'*/}}>
            {/* <p style={{textAlign: 'center', position: 'absolute', top: '50%', bottom: 0, left: 0, right: 0, fontSize: '1.2em'}}> */}
              <Map />
            {/* </p> */}
          </div>
          <div className="NoteColumns">
            <h1>What is this? Why is this?<a name="WhatIsThis" id="WhatIsThis" /></h1>
            <p>This map is an attempt to organize the field of data visualization to make it more approachable. The field can be overwhelming because it includes a dizzying array of…<br />
            </p>
            <ul>
              <li><strong>Types of visuals</strong> (dashboards, infographics, data exploration tools, data stories, data art, reports, scientific charts, etc.)<br />
              </li>
              <li><strong>Software</strong> (Excel, Tableau, Qlik, JMP, D3.js, ggplot, etc.)<br />
              </li>
              <li><strong>Skills</strong> (graphic design, storytelling, chart type selection, statistics, coding, etc.)<br />
              </li>
              <li><strong>Books &amp; courses</strong> (<em>Show Me the Numbers</em>, <em>Information is Beautiful</em>, <em>The Big Book of Dashboards</em>, <em>Practical Charts</em>, etc.)</li>
            </ul>
            <p>The idea is that, by creating a single map that contains all of these different types of terms, visuals, software, skills, books and courses, readers can see how they all relate to one another, and to different types of data visualizations. A more detailed description the rationales for creating this map can be found in the <a href="https://www.youtube.com/watch?v=4InqklhiwTk" target="_blank">"Guided Tour of the Map of Data Visualization" video</a>.<br />
            </p>
          </div>
          <div className="NoteColumns">
            <h1>Important caveats!</h1>
            <p>Experts disagree on how to define many of the terms in this map. This <a href="https://www.youtube.com/watch?v=4InqklhiwTk" target="_blank">"Guided Tour of the Map of Data Visualization" video</a> explains why the definitions on which the map is based were chosen. Some experts will undoubtedly disagree with these definitions, but definitions needed to be chosen in order to create the map.</p>
            <p>The software, books and courses mentioned in the map are a tiny sampling of resources that are associated with each "region" (type of visual). There are <em>many</em> other popular, high-quality resources that wouldn't "fit" in the map.</p>
            <p>Most of the software, books and courses mentioned in the map address several "regions" of the map, beyond the one(s) with which they're associated in the map. They're just often associated with that particular "region".</p>
          </div>
          <div className="NoteColumns">
            <h1>Who made this? Can I complain?<a name="WhoMadeIt" id="WhoMadeIt" /></h1>
            <p>This map was conceived and designed by <a href="https://www.practicalreporting.com/about-nick-desbarats" target="_blank">Nick Desbarats</a>, an independent author and educator in the fields of data visualization and dashboard design. Additional information on Nick and his upcoming <a href="https://www.practicalreporting.com/public-training-workshops" target="_blank">workshops</a> and <a href="https://www.practicalreporting.com/books">books</a> can be found at <a href="https://www.practicalreporting.com/" target="_blank">practicalreporting.com</a>.</p>
            <p>The interactive version of the map was developed by Alexandre Desbarats.</p>
            <p>Valuable contributions were made by the people listed in the "Contributors" section below.</p>
            <p><strong>This version of the map is a draft and will be revised based on reader feedback.</strong><a name="Suggestions" id="Suggestions" /> Please post comments and tag Nick on <a href="https://twitter.com/nickdesb" target="_blank">Twitter</a> or<a href="https://www.linkedin.com/in/nickdesbarats/" target="_blank"> LinkedIn</a>, adding the hashtag #mapofdataviz to ensure that your comment is seen. You can also message Nick directly via <a href="https://www.practicalreporting.com/contact" target="_blank">this form</a>. If you make a suggestion that gets incorporated into the map, your name will be added to the "special thanks" list below!
            </p>
          </div>
          <div className="clearboth">
          </div>
          <h1>Special thanks to...</h1>
          <p>These smart people who created books, courses or visuals that are in the map, or have made suggestions that have improved the map. If you make a suggestion that gets incorporated into a future revision of the map, we'll add your name to this list!</p>
          <p>Stacey Barr, Lizanne Béïque, Nadieh Bremer, Andy Cotgreave, Alexandre Desbarats, Jennifer Desbarats, Brent Dykes, Ann K. Emery, Stephen Few, Xan Gregg, David McCandless, Cole Nussbaumer Knaflic, Bryan Pierce, Jeff Shaffer, Cédric Sherer, Steve Wexler, Leland Wilkinson</p>
        </div>
        <div id="Footer">
          Copyright © 2022, Nick Desbarats, Practical Reporting Inc. 
        </div>
      </div>
    </div>
  )
}

export default App;